import { MenuItem, Select } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { map } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';

import { getSortByLabelFromOptionsByValue, getSortByOptions, if6CssPrefix, SortBy } from '../../../config';

interface OptionProps {
  value: SortBy;
  label: string;
}

interface Props {
  value?: SortBy;
  onChange: (value: SortBy) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  renderValueWithLabel?: boolean;
  fullWidth?: boolean;
}

export const EstateResultSortOrder: React.FunctionComponent<Props> = ({
  value,
  onChange,
  variant = 'standard',
  renderValueWithLabel = false,
  fullWidth = false,
}: Props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(value);
  const options: OptionProps[] = getSortByOptions(t);

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSelectedValue(value);
      onChange(value);
    },
    [onChange]
  );

  const renderValue = useCallback(
    (value) => {
      if (renderValueWithLabel) {
        return (
          <>
            <Typography className="sortOrder-label" variant="button">
              {t('sortOrder')}&nbsp;
            </Typography>
            <Typography className="sortOrder-selected" variant="button" fontWeight="bold">
              {getSortByLabelFromOptionsByValue(t, value)}
            </Typography>
          </>
        );
      }

      return getSortByLabelFromOptionsByValue(t, value);
    },
    [renderValueWithLabel, t]
  );

  const disableUnderlineSelectProps =
    variant === 'standard'
      ? {
          disableUnderline: true,
        }
      : {};

  const menuListProps = {
    MenuListProps: {
      className: 'result-order-menu-list',
    },
  };

  return (
    <Select
      className="result-order-select"
      fullWidth={fullWidth}
      variant={variant}
      value={selectedValue}
      onChange={handleChange}
      renderValue={renderValue}
      sx={{
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
          marginRight: 0,
        },
      }}
      MenuProps={{
        className: if6CssPrefix,
        ...menuListProps,
      }}
      {...disableUnderlineSelectProps}
    >
      {map(options, (option) => (
        <MenuItem disableRipple disableTouchRipple key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

EstateResultSortOrder.displayName = 'EstateResultSortOrder';
